import React from 'react'
import { useTranslation } from 'react-i18next'

import { PermissionType } from 'api/external/checkPermission/checkPermission'
import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'
import { useExportPipelines } from 'api/hooks/useExportPipelines/useExportPipelines'

import { PopOverItem, PopOverSection } from 'components/PopOverMenu'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'
import { useProjectNames } from 'hooks/useProjectInfo/useProjectNames'

import { usePipelineBrowserCommandProvider } from '../../effects/usePipelineBrowserCommand'
import { FileTreeReducerType } from '../../reducers/fileTreeReducer/types'
import CreatePipelineContextMenuEntries from '../CreatePipelineContextMenuEntries/CreatePipelineContextMenuEntries'

export interface FolderItemContextMenuProps {
  path: string
  handleImportPipelines: (e: React.MouseEvent<HTMLButtonElement>) => void
  isRootFolder?: boolean
  isEmpty?: boolean
}

const FolderItemContextMenu = ({
  path,
  handleImportPipelines,
  isRootFolder,
  isEmpty = false
}: FolderItemContextMenuProps) => {
  const { t } = useTranslation()
  const { enableImportExportPipelines } = useFlags()
  const { branchId, projectId } = useProjectInfo()
  const { projectName } = useProjectNames()
  const { onFileTreeCommand } = usePipelineBrowserCommandProvider()
  const { mutate: exportPipelines } = useExportPipelines('folder')

  const { hasPermission: canDelete } = useProjectPermission(
    'delete_pipelines',
    PermissionType.PROJECT
  )

  const { hasPermission: canCreate } = useProjectPermission(
    'create_pipelines',
    PermissionType.PROJECT
  )

  const handleCreateFolder = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation()

    onFileTreeCommand({
      type: FileTreeReducerType.CREATE_FOLDER,
      fileDetails: { path }
    })
  }

  const handleDeleteFolder = () => {
    onFileTreeCommand({
      type: FileTreeReducerType.DELETE_FOLDER,
      branch: branchId,
      project: projectId,
      fileDetails: { path }
    })
  }

  const handleExportFolder = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation()
    exportPipelines({
      paths: [path],
      fileName: `${projectName}_${branchId}_${path.replace(
        /\//g,
        '_'
      )}_${new Date().toISOString()}.zip`
    })
  }

  return (
    <PopOverSection>
      <CreatePipelineContextMenuEntries
        folderPath={path}
        entryContext="folder"
      />

      <PopOverItem
        disabled={!canCreate}
        onClick={handleCreateFolder}
        data-testid="folder-context-menu-create-folder"
      >
        {t('create-folder.context-menu.option')}
      </PopOverItem>

      {!isRootFolder && (
        <PopOverItem
          disabled={!canDelete}
          onClick={handleDeleteFolder}
          data-testid="folder-context-menu-delete-folder"
        >
          {t('delete-folder.context-menu.option')}
        </PopOverItem>
      )}
      {enableImportExportPipelines && (
        <>
          {!isEmpty && (
            <PopOverItem onClick={handleExportFolder}>
              {t('export-folder.context-menu.option')}
            </PopOverItem>
          )}
          <PopOverItem disabled={!canCreate} onClick={handleImportPipelines}>
            {t('import-to-folder.context-menu.option')}
          </PopOverItem>
        </>
      )}
    </PopOverSection>
  )
}

export default FolderItemContextMenu
